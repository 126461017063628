



























































import { ActivityTableData } from '@/presentation/wallet/model/ActivityTableData'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { EnglishMonths } from '@/data/EnglishMonths'
import { CurrencyEnum } from '@/enums/CurrencyEnum'
import { WalletActivityEnum } from '@/enums/WalletActivityEnum'
import { WalletActivitiesPresentation } from '@/presentation/wallet/WalletActivitiesPresentation'

@Component
export default class WalletActivityTable extends Vue {
  @Prop() readonly walletActivities!: Array<ActivityTableData>;
  @Prop() readonly currency!: keyof typeof CurrencyEnum;

  WalletActivityEnum = WalletActivityEnum;

  ActivityPresentation: WalletActivitiesPresentation = new WalletActivitiesPresentation();

  get tableTitle (): string {
    if (this.walletActivities[0].isWaitingDeposit) { return `${this.$t('commons.transfer_status.waiting_deposit')}` }
    const date: Date = new Date()
    const today: string = `${date.getFullYear()}-${date.getMonth() + 1}`
    const activitiesTransactionTime: String = this.walletActivities[0]
      .transaction_time
    if (activitiesTransactionTime.slice(0, 7) === today) { return `${this.$t('wallet.main.this_month')}` } else if (
      Number(activitiesTransactionTime.slice(5, 7)) + 1 ===
      Number(today.slice(5, 7))
    ) {
      return `${this.$t('wallet.main.last_month')}`
    } else {
      return `${this.$t('wallet.main.date', {
        YYYY: `${activitiesTransactionTime.slice(0, 4)}`,
        MM: this.getMonthTitleString(
          Number(activitiesTransactionTime.slice(5, 7))
        )
      })}`
    }
  }

  getMonthTitleString (month: number) {
    if (localStorage.getItem('locale') === 'en') return EnglishMonths[month]
    return month.toString()
  }

  getNumSign (tableData: ActivityTableData): string {
    if (tableData.isWaitingDeposit) return ''
    else if (
      tableData.transaction === WalletActivityEnum.WITHDRAW_BY_REMITTANCE ||
      tableData.transaction === WalletActivityEnum.WITHDRAW_BY_WALLET_REFUND
    ) { return '-' } else return '+'
  }

  isRemittance (transaction: WalletActivityEnum): boolean {
    return transaction === WalletActivityEnum.WITHDRAW_BY_REMITTANCE
  }

  getTagClasses (
    transaction: WalletActivityEnum
  ): {
    'transaction-tag': boolean;
    remittance: boolean;
    withdrawal: boolean;
    deposit: boolean;
  } {
    return {
      'transaction-tag': true,
      remittance:
                this.ActivityPresentation.getTransactionCategoryByEnum(
                  transaction
                ) === 'Remittance',
      withdrawal:
                this.ActivityPresentation.getTransactionCategoryByEnum(
                  transaction
                ) === 'Withdrawal',
      deposit:
                this.ActivityPresentation.getTransactionCategoryByEnum(
                  transaction
                ) === 'Deposit'
    }
  }

  clickRemittanceActivity (activity: ActivityTableData) {
    const path: string = '/history'
    if (activity.remittance_group_id === '0' || activity.isWaitingDeposit) {
      let routeData = this.$router.resolve({ path })
      window.open(routeData.href, '_blank')
    } else {
      let query = {
        details: activity.remittance_group_id
      }
      const route = this.$router.resolve({ path, query })
      window.open(route.href, '_blank')
    }
  }
}
