

































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Wallet } from '@/models/wallet/Wallet'
import { BankAccount } from '@/models/wallet/BankAccount'
import copy from 'copy-to-clipboard'

@Component
export default class DepositModal extends Vue {
  @Prop(Object) wallet!: Wallet
  @Prop(Object) readonly paymentMethodInformation!: BankAccount

  get title (): string {
    return `${this.wallet.currency.toUpperCase()} ${this.$t('wallet.main.deposit')}`
  }

  get subTitle (): string {
    const currencyBalance = {
      balance: this.wallet.balance.toLocaleString(),
      currency_name: this.wallet.currency
    }
    return this.$t('wallet.deposit.balance_current', currencyBalance) as string
  }

  get contentTitle (): string {
    return this.$t('wallet.deposit.account_details') as string
  }

  async copyAccountNumber () {
    const isCopied = copy(this.paymentMethodInformation.account_number)
    const messageType = isCopied ? 'success' : 'error'
    const message = isCopied ? this.$t('wallet.deposit.copy_modal') : this.$t('notification.errors.error')
    this.$message({ type: messageType, message: message as string })
  }

  close () {
    this.$emit('closeDepositDialog')
  }
}
